<template>
  <div class="row mx-0">
    <div class="col-12 col-lg-12 mx-auto px-0">
      <div class="card p-3">
        <h3 class="text-center">Todas as Notícias</h3>
        <!-- Filtro -->
        <div class="row mx-0 my-2">
          <div class="col-12 col-lg px-0">
            <div class="row mx-0 align-items-center">
              <div class="col px-1">
                <input id="formTitle" v-model="form.post_title" @keypress.enter="filter(true)" @keypress="block=true"
                  :placeholder="thead[0]" type="text" class="form-control border-0 border-bottom rounded-0" />
              </div>
              <div class="col-auto px-1">
                <select id="formStatus" v-model="form.post_status" @change="block=true" :placeholder="thead[1]"
                  class="form-control border-0 border-bottom rounded-0">
                  <option class="form-control" value="">Ambos</option>
                  <option class="form-control" value="publish">Publicado</option>
                  <option class="form-control" value="draft">Rascunho</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-auto px-0">
            <div class="row mx-0 align-items-center border-0 border-bottom">
              <div class="col px-1">
                <select class="form-control border-0" v-model="form.year" @change="block=true">
                  <option class="form-control" value=""> {{ lblDefault }} </option>
                  <option v-for="y in dating" :key="y" :value="y" class="form-control"> {{ y }} </option>
                </select>
              </div>
              <div class="col px-1">
                <select class="form-control border-0" v-model="form.month" @change="block=true">
                  <option class="form-control" value=""> {{ lblDefault }} </option>
                  <option v-for="m in 12" :key="m" :value="ftStart(m)" class="form-control"> {{ ftStart(m) }} </option>
                </select>
              </div>
              <div class="col px-1">
                <select class="form-control border-0" v-model="form.day" @change="block=true">
                  <option class="form-control" value=""> {{ lblDefault }} </option>
                  <option v-for="d in 31" :key="d" :value="ftStart(d)" class="form-control"> {{ ftStart(d) }} </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-auto px-0">
            <div class="row mx-0 align-items-center">
              <div class="col col-lg-auto px-1">
                <select v-model="form.author" class="form-control border-0 border-bottom rounded-0">
                  <option class="form-control" value=""> {{ lblDefault }} </option>
                  <option v-for="player in author.rows" :key="`author-${player.id}`" :value="player.id"
                    class="form-control">
                    {{ player.display_name }} </option>
                </select>
              </div>
              <div class="col-auto col-lg-auto px-1">
                <button @click="filter(true)" class="btn btn-success alert-success"> OK </button>
              </div>
              <button @click="clean()" class="border-0 bg-transparent col-auto px-0">
                <b-icon icon="trash" variant="danger" />
              </button>
            </div>
          </div>
        </div>
        <!-- Table Header -->
        <table class="d-none d-md-table table m-0">
          <thead>
            <tr>
              <th class="text-center" style="width:5%;">
                <b-icon icon="gear-fill" />
              </th>
              <th v-for="(h,hx) in thead" :key="`header-${hx}`" :style="`width:${(hx==0)?'50%;':'15%'}`"> {{ h }}
              </th>
            </tr>
          </thead>
        </table>
        <!-- Table Data -->
        <div id="section-content" class="content">
          <table v-if="news.count" class="table m-0 table-hover table-secondary align-middle">
            <tbody>
              <tr v-for="(n, nx) in news.rows" :key="`users-${nx}`">
                <td class="text-center" style="width:5%;">
                  <b-dropdown dropright size="sm" variant="secundary" no-caret>
                    <template #button-content>
                      <b-icon icon="gear-fill" animation="spin" />
                    </template>
                    <b-dropdown-item target="_blank" :href="host(n.guid)">
                      <b-icon icon="newspaper" variant="primary" />
                      <span class="px-2 text-primary">Acessar</span>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="getEditPost(n.id)">
                      <b-icon icon="pencil-square" variant="info" />
                      <span class="px-2 text-info">Editar</span>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="setStatus(n.id)">
                      <b-icon :icon="!status(n)?'lightning-charge':'journal-text'"
                        :variant="!status(n)?'success':'warning'" />
                      <span :class="`px-2 text-${!status(n)?'success':'warning'}`">
                        {{ !status(n) ? 'Publicar' : 'Rascunho' }}</span>
                    </b-dropdown-item>
                    <template v-if="$store.state.news.login.auth">
                      <b-dropdown-divider />
                      <b-dropdown-item v-b-modal.m-delete-post @click="post=n">
                        <b-icon icon="x-square" variant="danger" />
                        <span class="px-2 text-danger">Deletar</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </td>
                <td style="width:50%;min-width:300px;"> {{ n.post_title }} </td>
                <td :class="`text-${status(n)?'success':'orange'}`" class="small" style="width:15%">
                  <div class="d-flex align-items-center">
                    <b-icon :icon="`${n.user_status?'check-circle-fill':'slash-circle-fill'}`" class="mx-2" />
                    {{ status(n) ? 'Publicado':'Rascunho' }}
                  </div>
                </td>
                <td class="small" style="width:15%"> {{ status(n)? n.post_date : '' }} </td>
                <td> {{ n.ref_users.display_name }} </td>
              </tr>
            </tbody>
          </table>
          <p v-else-if="!loading" class="text-center">Nenhum registro encontrado!</p>
          <b-icon v-else icon="circle-fill" animation="throb" font-scale="4" class="w-100 my-3 my-lg-5 mx-auto" />
        </div>
        <!-- Paginate -->
        <div v-if="news.count" class="row mx-0 my-2 align-items-center justify-content-end">
          <div class="col-auto px-1 small"> {{ `${first} - ${end} de ${news.count || 0}` }} </div>
          <div class="col-auto px-1">
            <b-pagination v-model="pgt.page" @change="[pgt.page=$event,filter()]" :per-page="pgt.limit"
              :total-rows="news.count" :disabled="block" align="right" class="m-0" />
          </div>
        </div>
        <div class="row mx-0 my-3 justify-content-around">
          <button @click="$router.push('/noticias/inicio')" class="col-auto btn btn-default border">Voltar</button>
          <button @click="$router.push('/noticias/posts/criar')" class="col-auto btn btn-success border">Criar</button>
        </div>
      </div>
    </div>
    <b-modal id="m-delete-post" title="Deletar Publicação?" centered hideHeaderClose>
      <h5 class="text-center">Confirme para <strong class="text-danger">APAGAR</strong> a notícia:</h5>
      <h5 class="text-large my-4">Título: <strong>{{ post.post_title }}</strong></h5>
      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deletePost(post.id)">Apagar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        lblDefault: 'Todos',
        thead: ['Título', 'Status', 'Data', 'Autor'],
        pgt: {
          page: 1,
          limit: 10,
          offset: 0,
        },
        form: {
          post_title: '',
          post_status: '',
          post_date: null,
          author: '',
          year: new Date().getFullYear(),
          month: this.ftStart(new Date().getMonth() + 1),
          day: ''
        },
        query: '',
        post: {},
        block: false
      }
    },
    computed: {
      news() {
        return this.$store.state.news.news_list;
      },
      author() {
        return this.$store.state.news.users;
      },
      first() {
        return (this.pgt.offset + 1);
      },
      end() {
        return Math.min(this.pgt.page * this.pgt.limit, this.news.count);
      },
      dating() {
        return ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).map((x, i) => new Date().getFullYear() - 9 + i)
      },
    },
    beforeMount() {
      if (this.$store.state.news.filter_post) {
        this.form.post_title = this.$store.state.news.filter_post.post_title;
        this.form.post_status = this.$store.state.news.filter_post.post_status;
        this.form.author = this.$store.state.news.filter_post.author;
        this.form.year = this.$store.state.news.filter_post.year;
        this.form.month = this.$store.state.news.filter_post.month;
        this.form.day = this.$store.state.news.filter_post.day;
      }
    },
    mounted() {
      this.pgt.limit = Math.floor(document.querySelector('#section-content').offsetHeight / 50)
      this.$store.commit('SET_EDIT_POST', false)
      this.$store.dispatch('getUsers')
      this.filter()
    },
    methods: {
      status(payload) {
        return (payload.post_status == 'publish')
      },
      host(payload) {
        return (window.location.hostname.search('mbd') == -1) ?
          payload.replace('mbd.com.br', window.location.host) : payload;
      },
      getNewsList(ignoreMsg) {
        this.$store.commit('SET_NEWS_LIST', [])
        this.loading = true
        this.$store.dispatch('getNewsList', this.query)
          .then(() => [!ignoreMsg ? this.swal(true, 'Consulta realizada!') : ''])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => [this.loading = false, this.block = false])
      },
      ftStart(payload) {
        return String(payload).padStart(2, '0');
      },
      filter(reset, filter = false) {
        if (this.form.month && !this.form.year) return this.swal(false, 'Preencha o Ano')
        if (this.form.day && (!this.form.month || !this.form.year)) return this.swal(false, 'Preencha o Mês e Ano')
        this.form.post_date = `${this.form.year}`
        if (this.form.month) this.form.post_date += `-${this.form.month}`
        if (this.form.day) this.form.post_date += `-${this.form.day}`
        if (reset) this.pgt.page = 1;

        this.pgt.offset = parseInt(this.pgt.limit * Math.max((this.pgt.page - 1 || 0), 0));
        this.query = `limit=${this.pgt.limit}&offset=${this.pgt.offset}`;
        this.query += `&post_title=${this.form.post_title}`
        this.query += `&post_status=${this.form.post_status}`
        this.query += `&post_date=${this.form.post_date}`
        this.query += `&author=${this.form.author}`

        this.$store.commit('SET_FILTER_POST', this.form)
        this.getNewsList(filter);
      },
      clean() {
        this.form.post_title = '';
        this.form.author = '';
        this.form.post_status = '';
        this.form.day = '';
        this.form.month = '';
        this.form.year = '';
        document.querySelector('#formTitle').focus();
      },
      getEditPost(payload) {
        this.loading = true
        this.$store.dispatch('getEditPost', payload)
          .then(() => [this.$router.push('/noticias/posts/editar')])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false)
      },
      setStatus(payload) {
        this.loading = true
        const params = {
          post: payload,
          filter: this.query
        }
        this.$store.dispatch('setStatusPost', params)
          .then((res) => [this.swal(true, res.mensagem)])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false)
      },
      deletePost(payload) {
        this.loading = true
        const params = {
          post: payload,
          filter: this.query
        }
        this.$store.dispatch('deletePost', params)
          .then((res) => [this.swal(true, res.mensagem)])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => [this.loading = false, this.$bvModal.hide('m-delete-post')])
      }
    }
  }
</script>

<style scoped>
  .content {
    height: calc(100vh - 430px);
    overflow: auto;
  }

  .text-orange {
    color: #fd7e14;
  }

  @media screen and (max-width: 768px) {
    .content {
      height: calc(100vh - 430px);
    }
  }
</style>

<style>
  a.dropdown-item:active {
    background-color: #ced4da !important;
  }
</style>